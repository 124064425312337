<!--
 * @Author: luoyan
 * @Date: 2021-10-29 15:21:18
 * @LastEditTime: 2021-11-16 14:18:17
 * @Description: file content  订单明细
-->
<template>
  <comprehensive >
    <div class="ddmg-cart" v-loading="pageLoading">
      <div class="ddmg-cart-title">订单明细</div>
      <div class="ddmg-cart-header">
        <div>商品信息</div>
        <div>价格(元/吨)</div>
        <div>数量</div>
        <div>重量(吨)</div>
        <div>小计(元)</div>
      </div>
      <div class="ddmg-table-box">
        <div class="ddmg-cart-label">
          <div>卖方：{{ data.sellerCompanyName }}</div>
          <div>仓库：{{ data.warehouseName }}</div>
          <div>联系人：{{ data.sellerContactName }} {{ data.sellerContactPhone }}</div>
<!--          <div>联系卖家</div>-->
        </div>
        <el-table
          :data="data.items"
          ref="multipleTable"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :header-cell-style="{ background: '#f8f8f8', color: '#999' }"
        >
          <el-table-column type="selection" width="75" align="center" />
          <el-table-column prop="shopImg" align="center">
            <template slot-scope="scope">
              <span
                >{{ scope.row.category }} {{ scope.row.product_name }}
                {{ scope.row.material }} {{ scope.row.specifications }}</span
              >
            </template>
          </el-table-column>

          <el-table-column prop="price" align="center">
            <template slot-scope="scope">
              <span class="price" v-if=" scope.row.price  > 0">¥{{ scope.row.price }}</span>
              <span class="price" v-else>电议</span>
            </template>
          </el-table-column>
          <el-table-column prop="number" align="center">
            <template slot-scope="scope">
              <el-input
                v-model.number="scope.row.quantity"
                oninput="value=value.replace(/[^\d]/g,'')"
                autocomplete="off"
                style="width: 140px"
                size="mini"
                @change="handleInput(scope.row)"
              >
                <el-button
                  size="mini"
                  slot="prepend"
                  @click="delQuantity(scope.row)"
                  ><i class="el-icon-minus"></i
                ></el-button>
                <el-button
                  slot="append"
                  size="mini"
                  @click="addQuantity(scope.row)"
                  ><i class="el-icon-plus"></i
                ></el-button>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column prop="xzweight" align="center">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.weight"
                placeholder="请输入重量"
                @change="sumPrice(scope.row)"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="count" align="center">
            <template slot-scope="scope">
              <span class="count" v-if=" scope.row.goodTotal  > 0">¥{{ scope.row.goodTotal }}</span>
              <span class="count" v-else style="color:red">电议</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <i
                class="el-icon-delete"
                style="font-size: 18px; cursor: pointer"
                @click="handleDelete(scope.$index, scope.row)"
              ></i>
            </template>
          </el-table-column>
        </el-table>
        <div class="ddmg-cart-count">
          <el-checkbox
            style="margin-right: 22px"
            class="big-checkbox"
            type="checkbox"
            :checked="isCheck"
            @change="toggleSelection()"
          ></el-checkbox>
          <span style="margin-right: 30px">全选</span>
          <span style="margin-right: 20px">
            商品重量 :
            <span style="color: #ff4747"
              ><span class="format-quantity-sum">{{ countNumber }}</span
              >件</span
            >
            <span
              class="format-weight-sum"
              style="color: #ff4747; margin-left: 8px"
              >{{ countWeight }}吨</span
            >
            <span>
              商品总金额 :
              <span style="color: #ff4747" class="format-amount-sum">{{
                countPrice
              }}</span
              >元</span
            >
          </span>
        </div>
      </div>

      <div class="ddmg-cart-buttonlist">
        <div class="orderform_Paybutton">
          <div class="ConfirmAddressa orderform_Paybutton_div">
            <span style="margin-right: 68px">提货方式</span>
            <el-select v-model="tabPaybutton" placeholder="请选择">
              <el-option
                   v-for="item in cities"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value">
                <span>{{ item.label }}</span>
              </el-option>
            </el-select>
<!--            <button-->
<!--              type="button"-->
<!--              :class="!tabPaybutton ? 'Paybutton select_button' : 'Paybutton'"-->
<!--              style="outline: none"-->
<!--              @click="tabPaybutton = !tabPaybutton"-->
<!--            >-->
<!--              自提-->
<!--            </button>-->
<!--            <button-->
<!--              type="button"-->
<!--              :class="tabPaybutton ? 'Paybutton select_button' : 'Paybutton '"-->
<!--              style="-->
<!--                color: rgb(0, 0, 0);-->
<!--                background-color: rgb(255, 255, 255);-->
<!--                border: 1px solid rgb(204, 204, 204);-->
<!--                outline: none;-->
<!--              "-->
<!--              @click="tabPaybutton = !tabPaybutton"-->
<!--            >-->
<!--              配送-->
<!--            </button>-->
          </div>
<!--          <div-->
<!--            class="wuliu orderform_Paybutton_div"-->
<!--            v-if="tabPaybutton == true"-->
<!--          >-->
<!--            <input-->
<!--              type="hidden"-->
<!--              name="row[salesmanName]"-->
<!--              id="salesmanName"-->
<!--              value=""-->
<!--            />-->

<!--            <input-->
<!--              type="hidden"-->
<!--              name="row[salesmanMobile]"-->
<!--              id="salesmanMobile"-->
<!--              value=""-->
<!--            />-->
<!--            <span style="margin-right: 48px">推荐物流</span>-->
<!--            <button-->
<!--              class="Paybutton"-->
<!--              style="width: 233px"-->
<!--              type="button"-->
<!--              data-name="戴赣瓶"-->
<!--              data-telephone="15111105878"-->
<!--            >-->
<!--              汉牛物流 戴赣瓶15111105878-->
<!--            </button>-->
<!--            <button-->
<!--              class="Paybutton"-->
<!--              style="width: 233px"-->
<!--              type="button"-->
<!--              data-name="夏贤燃"-->
<!--              data-telephone="15674855590"-->
<!--            >-->
<!--              汉牛物流 夏贤燃15674855590-->
<!--            </button>-->
<!--            <button-->
<!--              class="Paybutton"-->
<!--              style="width: 233px"-->
<!--              type="button"-->
<!--              data-name="何翔"-->
<!--              data-telephone="17788924294"-->
<!--            >-->
<!--              汉牛物流 何翔17788924294-->
<!--            </button>-->
<!--          </div>-->
          <div
            class="delivery-address orderform_Paybutton_div"
            data-toggle="cxselect"
            data-selects="province,city,area"
          >
            <span style="margin-right: 68px">配送区域</span>
            <el-select
              style="margin-right: 20px"
              v-model="sheng"
              @change="shengChange"
              placeholder="省级地区"
            >
              <el-option
                v-for="item in shengList"
                :key="item.provinceCode"
                :label="item.provinceName"
                :value="item.provinceName"
              ></el-option>
            </el-select>
            <el-select
              style="margin-right: 20px"
              v-model="shi"
              @change="shiChange"
              :disabled="shiList.length == 0"
              placeholder="市级地区"
            >
              <el-option
                v-for="item in shiList"
                :key="item.cityCode"
                :label="item.cityName"
                :value="item.cityName"
              ></el-option>
            </el-select>
            <el-select
              v-model="qv"
              :disabled="qvList.length == 0"
              placeholder="区级地区"
            >
              　　
              <el-option
                v-for="item in qvList"
                :key="item.districtCode"
                :label="item.districtName"
                :value="item.districtName"
              ></el-option>
              　　</el-select
            >
          </div>
          <div
            class="detail-address orderform_Paybutton_div"
          >
            <span style="margin-right: 68px">详细地址</span>
            <el-input v-model="address" placeholder="详细地址"></el-input>
          </div>
          <div
            class="ConfirmAddressa orderform_Paybutton_div"
            style="position: relative"
          >
            <span style="margin-right: 23px">提货/交货日期</span>
            <el-date-picker
              v-model="dateList"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
            <p class="deliveryTimeMsg" id="delivery_time_msg"></p>
          </div>
          <div class="orderform_Paybutton_div">
            <span style="margin-right: 68px">支付方式</span>
            <button
              class="Paybutton paytype select_button"
              type="button"
              data-value="1"
            >
              线下支付
            </button>
            <input type="hidden" value="1" id="paytype" name="row[paytype]" />
          </div>
        </div>
      </div>

      <div class="orderform_paytext">
        <div style="height: 56px; font-size: 24px; color: #ff4747">
          <span
            style="
              font-size: 16px;
              color: rgb(102, 102, 102);
              margin-right: 10px;
              display: none;
            "
            id="coupon_amount"
            >优惠券抵扣<span id="coupon_amount_value" style="color: red"></span
          ></span>
          <span style="font-size: 16px; color: #666666">应付总额 :</span
          ><span
            v-if="countPrice == 0"
            class="format-amount-sum"
            >电议</span
          >
          <span v-else class="format-amount-sum" >{{countPrice}}</span>
        </div>
        <button
          @click="getOrder"
          class="btn cart-button"
          style="
            background-color: #ff4747;
            border: none;
            width: 180px;
            height: 56px;
            color: #ffffff;
            font-size: 24px;
            border-radius: 0;
            margin-left: 20px;
          "
        >
          下单提交
        </button>
      </div>
    </div>
  </comprehensive>
</template>

<script>
import * as Api from "@/utils/http";
import util from "@/libs/util.js";
import comprehensive from "./comprehensive.vue";
import {mapActions} from "vuex";
import storage from "@/utils/storage";
export default {
  components: {
    comprehensive,
  },
  name: "cart",
  data() {
    return {
      sheng: "",
      shi: "",
      qv: "",
      pageLoading: true,
      areaList:[],
      shengList: [],
      shiList: [],
      qvList: [],
      tabPaybutton:"",
      data: {},

      isCheck: true,
      dateList: "",
      countNumber: 0, //商品数量
      countWeight: 0, //商品重量
      countPrice: 0, //商品总金额
      address: "",
      multipleSelection: [],
      cities: [
        {value: '1', label: '自提'},
        {value: '4', label: '不包吊包到不包卸'},
        {value: '5', label: '不包吊包到包卸'},
        {value: '6', label: '包吊包到不包卸'},
        {value: '7', label: '包吊包到包卸'},
        {value: '8', label: '包吊不包到不包卸'},
        {value: '9', label: '包吊不包到包卸'},
        {value: '10', label: '代办运输'},
      ],
    };
  },
  created() {
    this.orderInit();
  },
  methods: {
    ...mapActions("ddmg/cart", ["getCartInfo", "getCartSubmit", "getArea"]),
    getOrder() {
      let userInfo = null;
      if(storage.getStorage("userInfo")){
         userInfo  =  storage.getStorage("userInfo");
      }
      let body = {
          head:{
            orderType: 2,
            sourceType:2,                //类型：Number  必有字段  备注：下单来源 1鲸GO、2大大买钢PC端、3EOC帮下单, 4 企业微信下单 10零购单自动生成订单
            sellerCompanyName:this.data.sellerCompanyName,                //类型：String  必有字段  备注：卖家公司名称
            sellerCompanyId:this.data.sellerCompanyId,                //类型：String  必有字段  备注：卖家公司id
            payMethod:1,                //类型：String  必有字段  备注：支付方式
            deliveryMethod:this.tabPaybutton,                //类型：String  必有字段  备注：配送方式 1-自提 2-配送
            province:this.sheng,                //类型：String  可有字段  备注：收货地址省份
            city:this.shi,                //类型：String  可有字段  备注：收货地址市
            area:this.qv,                //类型：String  可有字段  备注：收货地址区
            consignee:userInfo.user_name,                //类型：String  可有字段  备注：收货人
            consigneePhone:userInfo.user_mobile,                //类型：String  可有字段  备注：收货人手机号码
            address:this.address,                //类型：String  必有字段  备注：收货详细地址
            deliveryTime:this.dateList,                //类型：String  必有字段  备注：提货/交货日期
            companyName:userInfo.company_name,                //类型：String  必有字段  备注：买家公司
            companyId:userInfo.company_id,                //类型：String  必有字段  备注：买家公司id
            contactName:userInfo.user_name,                //类型：String  必有字段  备注：买家联系人
            contactPhone:userInfo.user_mobile,                //类型：String  必有字段  备注：买家联系方式
            cartId:this.data.cartId                 //类型：String  必有字段  备注：购物车ID 多个已英文逗号分割
          },
          details:this.data.items,
      };
      console.log(body)
        this.getCartSubmit(body).then(res=>{
            if (res.msgcode == 1) {
                this.$message.success('下单成功');
                this.JumpCenter(3)
            }
        });

    },
    //省
   shengChange() {
      this.shi =''
      this.qv =''
      this.shiList = [];
       this.qvList = [];
      this.areaList.citys.forEach((item) => {
         if(item.provinceName ==  this.sheng){
             this.shiList.push(item);
         }
      });
    },
    //市
    shiChange() {
      this.qvList = [];
      this.areaList.districts.forEach((item) => {
          if(item.cityName ==  this.shi){
              this.qvList.push(item);
          }
      });
    },
    //下单新增初始化
    orderInit() {
      this.pageLoading = true;
      this.getArea().then(obj=>{
          this.areaList = obj.resultdata
          this.shengList= obj.resultdata.provinces;
      });
      let type = this.$route.query.type;
      let id = this.$route.query.id;
      let quantity = this.$route.query.quantity;
      let weight = this.$route.query.weight;
      let body = {
        orderType: 2,
        orderTypeSpotMethod: type == '1' ? 2 : 1,
        goodsId:type == '1' ? id : null,
        cartId:type == '2' ? id : null,
        quantity:quantity,                //类型：String  必有字段  备注：现货下单 数量
        weight:weight                //类型：String  必有字段  备注：现货下单 重量
      };
      this.getCartInfo(body).then(res=>{
          res.order.items.forEach((item) => {
            item.goodTotal = item.amount * 1;
            item.check = true;
          });
          this.data = res.order;
          this.$refs.multipleTable.toggleAllSelection();
          this.pageLoading = false;
      });
      // let type = this.$route.query.type;
      // let body = {};
      // console.log("type", type);
      // this.id = this.$route.query.id;
      // console.log(this.id)
      // if (type == 1) {
      //   body = {
      //     order_type: 3, //订单类型 1 自助下单 7 工程下单 3现货下单
      //     order_type_spot_method: 2, //	现货下单方式 1 购物车 2 直接下单
      //     goods_id: this.id,
      //     quantity: 1,
      //     weight: 1,
      //   };
      // } else {
      //   body = {
      //     order_type: 3, //订单类型 1 自助下单 7 工程下单 3现货下单
      //     order_type_spot_method: 1, //	现货下单方式 1 购物车 2 直接下单
      //     cart_id: this.id,
      //   };
      // }
      // Api.startReq(
      //   "pc/buyer/orderProcess/materialOrder/create",
      //   body,
      //   "get"
      // ).then((res) => {
      //   console.log("新增订单初始化", res);
      //   if (res.code == 1) {
      //     res.data.order.items.forEach((item) => {
      //       item.goodTotal = item.amount * 1;
      //       item.check = true;
      //     });
      //     this.data = res.data.order;
      //     this.$refs.multipleTable.toggleAllSelection();
      //   } else {
      //   }
      // });
    },

    // 删除单个商品
    handleDelete(index, row) {
      this.$confirm("确定删除该商品？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
            if(this.data.items.length > 1){
                //删除数组中指定的元素
                this.data.items.splice(index, 1);
                this.$message({
                    type: "success",
                    message: "删除成功!",
                });
            }else{
                this.$message({
                    type: "error",
                    message: "至少保留一条数据!",
                });
            }

        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 数量变化触发
    handleInput(value) {
      console.log(value);
      if (
        null == value.quantity ||
        value.quantity == "" ||
        value.quantity == 0
      ) {
        value.quantity = 1;
      }
      // if (value.adjustment_factor != 0) {
      //   //理算系数不为0时，价格为数量*理算系数*价格
      //   value.goodTotal = (
      //     value.quantity *
      //     1 *
      //     value.adjustment_factor *
      //     1 *
      //     value.price *
      //     1
      //   ).toFixed(2); //保留两位小数
      //   value.weight = (
      //     value.quantity *
      //     1 *
      //     value.adjustment_factor *
      //     1
      //   ).toFixed(2);
      // }

      // console.log(value.goodTotal);
      //增加商品数量也需要重新计算商品总价
      // this.handleSelectionChange(this.multipleSelection);
    },
    //增加数量
    addQuantity(addGood) {
      if (typeof addGood.quantity == "string") {
        addGood.quantity = parseInt(addGood.quantity);
      }
      addGood.quantity += 1;
      // if (addGood.adjustment_factor != 0) {
      //   addGood.goodTotal = (
      //     addGood.quantity *
      //     1 *
      //     addGood.adjustment_factor *
      //     1 *
      //     addGood.price *
      //     1
      //   ).toFixed(2); //保留两位小数
      //   addGood.weight = (
      //     addGood.quantity *
      //     1 *
      //     addGood.adjustment_factor *
      //     1
      //   ).toFixed(2);
      // }
      // this.countNumber = 0;
      // this.countWeight = 0;
      // this.countPrice = 0;
      // this.data.items.forEach((row) => {
      //   console.log(row.check);
      //   if (row.check == true) {
      //     this.countNumber += row.quantity * 1;
      //     this.countWeight += Number((row.weight * 1).toFixed(4));
      //     this.countPrice += Number((row.goodTotal * 1).toFixed(2));
      //   }
      // });
      //addGood.goodTotal = (addGood.quantity * addGood.price).toFixed(2); //保留两位小数
      //  this.handleSelectionChange(this.multipleSelection)
    },
    //减少数量
    delQuantity(delGood) {
      if (typeof delGood.quantity == "string") {
        delGood.quantity = parseInt(delGood.quantity);
      }
      if (delGood.quantity > 1) {
        delGood.quantity -= 1;
        // if (delGood.adjustment_factor != 0) {
        //   delGood.goodTotal = (
        //     delGood.quantity *
        //     1 *
        //     delGood.adjustment_factor *
        //     1 *
        //     delGood.price *
        //     1
        //   ).toFixed(2); //保留两位小数
        //   delGood.weight = (
        //     delGood.quantity *
        //     1 *
        //     delGood.adjustment_factor *
        //     1
        //   ).toFixed(2);
        // }
        // this.countNumber = 0;
        // this.countWeight = 0;
        // this.countPrice = 0;
        // this.data.items.forEach((row) => {
        //   if (row.check == true) {
        //     this.countNumber += row.quantity * 1;
        //     this.countWeight += Number((row.weight * 1).toFixed(4));
        //     this.countPrice += Number((row.goodTotal * 1).toFixed(2));
        //   }
        // });
        //delGood.goodTotal = (delGood.quantity * delGood.price).toFixed(2); //保留两位小数
        //    this.handleSelectionChange(this.multipleSelection)
      }
    },
    //重量计算
    sumPrice(val) {
      val.goodTotal = (val.price * 1 * (val.weight * 1)).toFixed(2);
      this.countNumber = 0;
      this.countWeight = 0;
      this.countPrice = 0;
      this.data.items.forEach((row) => {
        if (row.check == true) {
          this.countNumber += row.quantity * 1;
          this.countWeight += Number((row.weight * 1).toFixed(4));
          this.countPrice += Number((row.goodTotal * 1).toFixed(2));
        }
      });
    },
    toggleSelection() {
      //table全选
      this.isCheck = !this.isCheck;
      if (this.isCheck == true) {
        this.$refs.multipleTable.toggleAllSelection();
        this.countNumber = 0;
        this.countWeight = 0;
        this.countPrice = 0;
        this.data.items.forEach((row) => {
          this.countNumber += row.quantity * 1;
          this.countWeight += Number((row.weight * 1).toFixed(4));
          this.countPrice += Number((row.goodTotal * 1).toFixed(2));
          row.check = true;
        });
      } else {
        this.countNumber = 0;
        this.countWeight = 0;
        this.countPrice = 0;
        this.$refs.multipleTable.clearSelection();
        this.data.items.forEach((row) => {
          row.check = false;
        });
      }
    },
    //table单选
    handleSelectionChange(selection) {
      this.countNumber = 0;
      this.countWeight = 0;
      this.countPrice = 0;
      if (selection.length > 0) {
        selection.forEach((row) => {
          this.countNumber += row.quantity * 1;
          this.countWeight += Number((row.weight * 1).toFixed(4));
          this.countPrice += Number((row.goodTotal * 1).toFixed(2));
          this.data.items.forEach((rowx) => {
            if (row.goods_id == rowx.goods_id) {
              rowx.check = true;
            } else {
              rowx.check = false;
            }
          });
        });
      } else {
        this.data.items.forEach((rowx) => {
          rowx.check = false;
        });
      }
    },
  },
  computed: {},
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" >
.detail-address .el-input {
  width: 60%;
}
.ddmg-cart {
  width: 1200px;
  margin: 0 auto;
  .ddmg-cart-title {
    height: 69px;
    line-height: 69px;
    font-size: 18px;
    color: #666666;
  }
  .ddmg-cart-header {
    height: 40px;
    line-height: 40px;
    padding-left: 10%;
    font-size: 14px;
    color: #666666;
    background-color: #f4f4f4;
    margin-bottom: 10px;
    display: flex;
  }
  .ddmg-cart-header div {
    margin-right: 13%;
  }
  .ddmg-cart-label {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #999999;
    padding-left: 11.5%;
    background-color: #f5f8ff;
    display: flex;
  }
  .ddmg-cart-label div {
    margin-right: 30px;
  }
  .ddmg-table-box {
    overflow: hidden;
    border: 1px solid #dddddd;
  }
}
.ddmg-cart .has-gutter {
  display: none !important;
}
.ddmg-cart-count {
  height: 75px;
  line-height: 75px;
  font-size: 14px;
  color: #666666;
  padding-left: 32px;
}
.ddmg-cart .el-table__row {
  height: 96px;
  line-height: 96px;
}

.orderform_Paybutton .orderform_Paybutton_div {
  width: 1200px;
  line-height: 40px;
  height: 40px;
  font-size: 18px;
  color: #666666;
  margin: 30px 0px;
}
.orderform_Paybutton .Paybutton {
  cursor: pointer;
  width: 130px;
  height: 40px;
  line-height: 40px;
  border: 1px solid rgb(204, 204, 204);
  display: inline-block;
  margin: 0px;
  margin-right: 20px;
  text-align: center;
  font-size: 14px;
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
}
.select_button {
  background-color: #3d7dff !important;
  border: 1px solid #3d7dff !important;
  color: #fff !important;
}
.orderform_paytext {
  border-top: 1px solid #dddddd;
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 56px;
  line-height: 56px;
  padding: 50px 0;
}
</style>
